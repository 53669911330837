<template>
	<div class="detail">
		
		<div class="topbox">
			<!-- <div class="tit1">IP宇宙</div> -->
			<div class="txtcont">
				<img :src="detail.logo" alt="" class="avtar">
				<div class="txts">
					<div class="tit2">{{detail.name}}</div>
					<div class="type">{{detail.ip_type_level1}}/{{detail.ip_type_level2}}</div>
				</div>
			</div>
			<div class="tags">
				<span v-for="tag in detail.tags" class="lable">
				    {{tag}}
				</span>
			</div>
		</div>
		<div class="miancont">
			<div class="infobox">
				<div class="line">
					<div class="lable">合作内容：</div>
					<div class="txt"><template v-for="item,index in detail.cooperation_type">{{item}} <template  v-if="index!=detail.cooperation_type.length-1">，</template ></template></div>
					
				</div>
				<div class="line">
					<div class="lable">价格区间：</div>
					<div class="txt" v-if="detail.cooperation_price_down=='' || detail.cooperation_price_up==''">--</div>
					<div class="txt" v-else>{{detail.cooperation_price_down}} - {{detail.cooperation_price_up}}</div>
				</div>
				
				<div class="line">
					<div class="lable">付款形式：</div>
					<div class="txt">{{detail.pay_type}}</div>
				</div>
				<div class="line">
					<div class="lable">物料审核周期：</div>
					<div class="txt">{{detail.material_audit_range}}</div>
				</div>
				<div class="line">
					<div class="lable">合作周期：</div>
					<div class="txt">
						<template v-for="item,index in detail.cooperation_range">
							{{item}}
							<template  v-if="index!=detail.cooperation_range.length-1">，</template >
						</template>
					</div>
				</div>
			</div>
			<div class="pro">
				<div class="tit">简介</div>
				<div class="desc">
					 {{detail.desc}}
				</div>
			</div>
			<div class="bottips">
					<img src="@/assets/h5/images/detail/ico1.jpg" alt="" class="ico1">
					<div class="txts">
						查看更多信息请移步“<strong>IP宇宙官网</strong>”  <br>
						PC端百度“<span>IP宇宙</span>”官网即可访问
					</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	
	var docEl = document.documentElement,
	resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
	recalc = function() {
		var clientWidth = docEl.clientWidth;
		if (!clientWidth) return;
		docEl.style.fontSize = 100 * (clientWidth / 750) + 'px';
	};
	recalc()
	// if (!document.addEventListener) return;
	window.addEventListener(resizeEvt, recalc, false);
	document.addEventListener('DOMContentLoaded', recalc, false);
	export default {
		data(){
			return {
				detail:{
					// cooperation_type:['自媒体','授权合作','衍生品'],
					// cooperation_price_down:'1万',
					// cooperation_price_up:'2万',
					// pay_type:'预付比例50%以下',
					// material_audit_range:'预付',
					// cooperation_range:['自媒体','授权合作','衍生品衍生品衍生品'],
				},
				iid:'',
			}
		},
		created(){
			this.iid = this.$route.query.iid
			this.getDetail();
		},
		watch:{
			$route(){
				if(this.$route.query.iid){
					this.iid = this.$route.query.iid
					this.getDetail();
				}
			},
		},
		methods:{
			getDetail(){
				this.API.ip_detail({"ipid":this.iid},(res)=>{
				    if(res.code == 0){
				        this.detail = res.data;  
				    }
				})
			},
		},
		onUnmounted(){
			console.log('11111111111111111111111111111111')
			
		},
		destroyed(){
			console.log('11111111111111111111111111111111')
		}
		
	}
</script>

<style scoped>
	.detail{margin: 0 auto;}
	.topbox{border-bottom:#EDEEEF .12rem solid;padding: .48rem .74rem;}
	.topbox .tit1{font-size: .32rem;color: #0B0B0B;text-align: center;font-weight: bold;padding: .5rem 0;}
	.topbox .txtcont{display: flex;padding: 0 ;}
	.topbox .txtcont .avtar{flex:none;width: 1.04rem;border-radius: 100%;margin-right: .18rem;height: 1.04rem;;}
	.topbox .txtcont .tit2{font-size: .32rem;color: #0B0B0B;font-weight: bold;}
	.topbox .txtcont .type{background: #FE7E3D;color: #fff;font-size: .24rem;border-radius: .4rem;padding: 0 .1rem;line-height: .4rem;margin-top: .16rem;}
	.tags{margin-top: .42rem;}
	.tags span{height: .44rem;background: rgba(255, 255, 255, 0.1);border-radius: .08rem;border: 1px solid #E3E6EC;padding: 0 .15rem;font-size: .28rem;color: #6A7084;margin-right: .2rem;}
	.infobox{background: #FFF8F4;border-radius: .16rem;border: .02rem solid #F9ECE4;margin: .4rem .32rem;padding: .2rem .4rem;;}
	.infobox .line{margin-top: .16rem;display: flex;}
	.infobox .line .lable{color: #504D43;font-size: .28rem;width: 1.96rem;flex:none;line-height: .4rem;}
	.infobox .line .txt{color: #29110C;font-size: .28rem;line-height: .4rem;}
	.pro .tit{font-size: .32rem;color: #0B0B0B;border-left: #2973FF .06rem solid;line-height: .34rem;padding-left: .26rem;margin-top: .56rem;;}
	.pro .desc{font-size: .28rem;color: #606060;line-height: .44rem;margin-top: .34rem;padding: 0 .32rem;}
	.bottips{margin: .78rem .48rem;background: #FBFBFB;border-radius: .4rem;padding: .68rem .4rem;margin-bottom: .2rem;display: flex;align-items: center;}
	.bottips .ico1{flex:none;margin-right: .3rem;width: .6rem;height: .6rem;}
	.bottips .txts{color: #1B1E21;font-size: .28rem;line-height: .52rem;}
	.bottips .txts span{color: #2668E4;font-size: .28rem;}
	.bottips .txts strong{font-size: .28rem;}
</style>
